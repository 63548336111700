import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { HelpComponent } from '../help/help.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaoService } from '@core/services/pao.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { Observable, forkJoin } from 'rxjs';
import {
  GlobalDataService,
  FichierPersoStandaloneService,
  PersonalisationService,
  AdressesDestinatairesService,
  CampagneAdressesService,
  CampagnePlanificationService,
  PersonalisationSfService,
  AdressesDestinatairesSfService,
} from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { environment as env } from 'environments/environment';
import { IframeChili } from '@core/models/iframe-chili.model';
import { HttpResponse } from '@angular/common/http';
import { stepperStandaloneIndexes } from '@core/constants/stepperIndexes';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { NavigateUtil } from '@shared/utils/navigate.util';

@Component({
  selector: 'app-campagne-creation',
  templateUrl: './campagne-creation.component.html',
  styleUrls: ['./campagne-creation.component.scss'],
})
export class CampagneCreationComponent implements OnInit {
  @Input()
  public stepper!: MatStepper;

  title: string = 'Créations';

  // Max file size allowed for uploading creation file
  public static MAX_SIZE_FILE = 1073741824;

  // Chili
  public isLoadingChiliUrl = false;
  public isLoadingChiliIframe = false;
  public templatesType: 'GENERIC' | 'CUSTOM' | null = undefined;
  public iframeHasError = false;
  public iframe: SafeResourceUrl = undefined;
  public isAuthenticated = false;
  private SAVE_MESSAGE = { save: true };
  private targetOrigin = env.targetOriginChili;

  // Fichier de ciblage
  isCiblageLoaded = false;
  importFichierCiblage: string = undefined;
  isDeletingFichierCiblage = false;

  // State and step state on save send to Chili
  public isSaveSendToChili = false;
  public stepChoose = undefined;

  /**
   * Listener for postMessage
   */
  @HostListener('window:message', ['$event'])
  private onMessage(event: MessageEvent) {
    // Read from Chili
    const origin = event.origin;
    if (!origin.startsWith(env.targetOriginChili)) {
      return;
    }
    if (this.stepper.selectedIndex === stepperStandaloneIndexes.Creation) {
      console.log('Listener data', event);
      this.handleChiliPostMessage(event);
    }
  }

  constructor(
    public _sanitizer: DomSanitizer,
    public _HelpComponent: HelpComponent,
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _fichierCreaService: FichierCreaService,
    public _paoService: PaoService,
    public _fichierPersoStandaloneService: FichierPersoStandaloneService,
    public _personalisationService: PersonalisationService,
    private _PersonalisationSfService: PersonalisationSfService,
    private _AdressesDestinatairesSfService: AdressesDestinatairesSfService,
    public _adressesDestinatairesService: AdressesDestinatairesService,
    private _CampagneService: CampagneService,
    public _campagneAdressesService: CampagneAdressesService,
    public _CampagnePlanificationService: CampagnePlanificationService,
    private _ChampsFusionService: ChampsFusionService,
    private _FichierPersoStandalone: FichierPersoStandaloneService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (
      this._GlobalDataService.Campagne.creationType === 'chili' &&
      this._GlobalDataService.Campagne.importFile
    ) {
      this.isCiblageLoaded = true;
    }
    this._GlobalDataService.stepper = this.stepper;
  }

  checkCreationSelection(option: 'upload' | 'chili') {
    if(this.isSaveSendToChili) {
      return;
    }
    console.log(this._GlobalDataService.Campagne.creationType + ' --> ' + option);

    // Case 1 : no change
    if (option === this._GlobalDataService.Campagne.creationType) return;

    // Case 2 : select Chili
    if (option === 'chili') {
      // Check Typologie completion
      if (!this.checkTypologie()) {
        console.log('Typologie check failed.');
        this._GlobalDataService.alert(
          'Vous n’avez pas sélectionné de produit, veuillez paramétrer l’étape Typologie.'
        );
        return;
      }

      // Check user chili authentication
      if (!this.checkAuthentication()) {
        console.log('Chili authentification failed.');
        this._GlobalDataService.alert(
          'Vous n’êtes pas autorisé à accéder au Studio de création.'
        );
        return;
      }

      /**
       * Check deletion of modifications in step 3 and step 4:
       *  - Upload creation files
       *  - Personnalisation files
       *  - Ficher de ciblage
       *  - Champs fusion personnalisation
       *  - Champs fusion pave adresse
       */
      if (
        this._GlobalDataService.CampagneExt.FichiersCreation.length > 0 ||
        this._GlobalDataService.Campagne.importFile?.length > 4 ||
        this._GlobalDataService.CampagneExt.ChampsFusion.length > 0
      ) {
        const onSuccess = () => {
          console.log('Delete modifications confirmed.');

          // Call delete fichier de ciblage and Champs fusion
          switch(this._GlobalDataService.CONTEXT) {
            case "STANDALONE": 
              this.deleteStandaloneConfigurations();
              break;
            case "SALESFORCE":
              this.deleteSalesforceConfigurations();
              break;
            case "ELOQUA":
              this.deleteEloquaConfigurations();
              break;
          }

          // Call change option
          this.creationSelection(option);
        };

        const onCancel = () => {
          console.log(
            'Cancal on delete creation files and personnalisation files.'
          );
          return;
        };

        this._GlobalDataService.confirmCreationOption({
          titre: 'Attention !',
          message:
            'En accédant au studio de création votre fichier de ciblage importé et votre configuration seront perdus, souhaitez-vous poursuivre ?<br>- Fichiers de créations<br>- Fichiers de personnalisations',
          BouttonConfirmation: true,
          onConfirm: onSuccess.bind(this),
          onCancel: onCancel.bind(this),
        });
      } else {
        // Call change option
        this.creationSelection(option);
      }
    }

    // Case 3 : select Upload
    if (option === 'upload') {
      // Creation option is not yet seleted
      if (this.creationSelection === undefined) {
        this.creationSelection(option);
        return;
      }

      /**
       * Check deletion of modifications if pass from 'chili' to 'upload':
       *  - Chili creation
       *  - Ficher de ciblage uploaded
       */
      if (
        this.iframe ||
        this._GlobalDataService.CampagneExt.chiliCreationStatus ||
        (this._GlobalDataService.isStandaloneContext() 
          && this._GlobalDataService.Campagne.importFile?.length > 4)
      ) {
        const onSuccess = () => {
          console.log('Delete modifications confirmed.');

          // Close iframe
          this.iframe = undefined;
          this.templatesType = undefined;
          this._GlobalDataService.isIframeChiliLoaded = false;

          // Reset contact file state
          this.isCiblageLoaded = false;

          // Call change option
          this.creationSelection(option);
        };

        const onCancel = () => {
          console.log(
            'Cancal on delete creation files and personnalisation files.'
          );
          return;
        };

        this._GlobalDataService.confirmCreationOption({
          titre: 'Attention !',
          message:
            'En accédant au studio de création votre fichier de ciblage importé et votre configuration seront perdus, souhaitez-vous poursuivre ?<br>- Fichiers de créations<br>- Fichiers de personnalisations',
          BouttonConfirmation: true,
          onConfirm: onSuccess.bind(this),
          onCancel: onCancel.bind(this),
        });
      } else {
        // Call Call change option
        this.creationSelection(option);
      }
    }
  }

  /**
   * Change creation options
   * Invoke delete modifications in Step 4 Personnalisation in Backend:
   *  - Ficher de ciblage
   *  - Champs fusion personnalisation
   *  - Champs fusion pave adresse
   * @param option
   * @returns
   */
  public creationSelection(option: 'upload' | 'chili') {
    console.log(this._GlobalDataService.Campagne.creationType + ' --> ' + option);

    // Update stepper completion
    this._GlobalDataService.CampagneExt.chiliCreationStatus = undefined;
    this._GlobalDataService.checkStepCompletion();

    // Update campange
    try {
      this._GlobalDataService.Campagne.creationType = option;

      // SF : Initialize data extension
      this._GlobalDataService.Campagne.ExtensionId = undefined

      // Initialize dates
      this._GlobalDataService.Campagne.DateRemiseImprimeur = undefined;
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = undefined;
      this._GlobalDataService.Campagne.DateDeDebut = undefined;
      this._GlobalDataService.Campagne.DateDeFin = undefined;
      this._CampagnePlanificationService.form
        .get('DateRemiseImprimeur')
        .setValue(undefined);
      this._CampagnePlanificationService.form
        .get('DateTheoriqueDepotLaPoste')
        .setValue(undefined);
      this._CampagnePlanificationService.form
        .get('DateEstimeeDistribution')
        .setValue(undefined);
      this._CampagnePlanificationService.form
        .get('DateDeDebut')
        .setValue(undefined);
      this._CampagnePlanificationService.form
        .get('DateDeFin')
        .setValue(undefined);

      // Initialize adresse de retour
      this._GlobalDataService.Campagne.Alliage = undefined;
      this._GlobalDataService.Campagne.Adresse = undefined;
      this._GlobalDataService.CampagneExt.Adresse = undefined;
      this._GlobalDataService.CampagneExt.selectedAdresses = [];
      this._campagneAdressesService.form.reset();

      // Initialize chiliCreationStatus
      if (option === 'upload') {
        this._GlobalDataService.CampagneExt.chiliCreationStatus = undefined;
        console.log('Set chiliCreationStatus to :', this._GlobalDataService.CampagneExt.chiliCreationStatus)
      }

      // Set creationType
      this._GlobalDataService.Campagne.creationType = option;

      // Init personnalisation variables
      this._GlobalDataService.importOption = undefined;
      console.log('Set GlobalDataService.importOption to :', this._GlobalDataService.importOption);
      this._GlobalDataService.csvFileName = undefined;
      console.log('Set GlobalDataService.csvFileName to :', this._GlobalDataService.csvFileName);
      this._GlobalDataService.csvHeaders = undefined;
      console.log('Set GlobalDataService.csvHeaders to :', this._GlobalDataService.csvHeaders);
      this._GlobalDataService.csvRows = undefined;
      console.log('Set GlobalDataService.csvRows to :', this._GlobalDataService.csvRows);

      // Init Campagne ciblage
      this._GlobalDataService.Campagne.importOption = undefined;
      console.log('Set Campagne.importOption to :', this._GlobalDataService.Campagne.importOption);
      this._GlobalDataService.Campagne.importFile = undefined;
      console.log('Set Campagne.importFile to :', this._GlobalDataService.Campagne.importFile);

      // Call update campagne
      this.updateCampagne();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  public loadChili() {
    // Check Typologie completion
    if (!this.checkTypologie()) {
      console.log('Typologie check failed.');
      this._GlobalDataService.alert(
        'Vous n’avez pas sélectionné de produit, veuillez paramétrer l’étape Typologie.'
      );
      return;
    }

    this.iframe = undefined;
    this.isLoadingChiliUrl = true;
    this.templatesType = undefined;
    const campaignId = this._GlobalDataService.Campagne.IdCampagne;
    this._paoService.getIframe(campaignId).subscribe({
      next: (res: HttpResponse<IframeChili>) => {
        this.isLoadingChiliUrl = false;

        // TODO: Ne plus afficher le message quand une création Chili est associée à la campagne.
        if (res.status === 204) {
          this._GlobalDataService.alert(`
            Attention !<br />
            Aucun template n’est disponible pour le produit sélectionné.<br />
            Veuillez-vous rapprocher de votre contact au sein de La Poste pour créer un template personnalisé.
          `);
        } else if (res.ok && res.body?.iFrame) {
          this.isLoadingChiliIframe = true;
          this.iframe = this._sanitizer.bypassSecurityTrustResourceUrl(
            res.body.iFrame
          );

          // Go to top
          NavigateUtil.goToTopSideNavContent(this.el);
          
          console.log('this.iframe', this.iframe);
        } else {
          this._GlobalDataService.alert(`
            Attention !<br />
            Aucun template n’est disponible pour le produit sélectionné.<br />
            Veuillez-vous rapprocher de votre contact au sein de La Poste pour créer un template personnalisé.
          `);
        }
      },
      error: (_) => {
        this._GlobalDataService.alert(
          `Nous rencontrons actuellement un problème technique.<br />
          Veuillez réessayer ultérieurement. Merci de votre compréhension.`
        );
        this.isLoadingChiliUrl = false;
      },
    });
  }

  public getIframe(): SafeResourceUrl {
    return this.iframe;
  }

  /**
   * Delete Call 'Fichiers de créations' and 'Fichiers de personnalisations'
   */
  public deleteUploadFilesCall() {
    // update CampagneExt
    this._GlobalDataService.CampagneExt.FichiersCreation = [];
    this._GlobalDataService.CampagneExt.NbFichiersCreation = 0;
  }

  /**
   * Check close chili and call save()
   * Update Stepper completion
   */
  leaveAction() {
    if(this.isSaveSendToChili) {
      return;
    }

    // Just close iframe if iframe is not fully loaded or has error
    if (this.isLoadingChiliIframe || this.iframeHasError) {
      this.exitChili();
      return;
    }

    this._paoService.quitChili((isSaved: boolean) => {
      if (isSaved) {
        console.log('Confirm on saving Chili Creation.');
        this.isSaveSendToChili = true;
        this.stepChoose = undefined;
        this.sendSavingPostMessage();
      }
    });
  }

  /**
   * Exit Chili iframe page
   */
  private exitChili() {
    this._GlobalDataService.isIframeChiliLoaded = false;
    this.isLoadingChiliIframe = false;
    this.iframe = undefined;
    this.templatesType = undefined;
    this.isSaveSendToChili = false;
    this.iframeHasError = false;
  }

  sendSavingPostMessage() {
    try {
      const iframeChili = this.el.nativeElement.querySelector('#iframe');
      console.log(iframeChili);

      if (iframeChili) {
        console.log("(postMessage send) Message : '" + this.SAVE_MESSAGE + "'; Target: '" + this.targetOrigin + "'");
        iframeChili.contentWindow.postMessage(
          this.SAVE_MESSAGE,
          this.targetOrigin
        );
      } else {
        console.error('iframe not found');
      }
    } catch (error) {
      console.error(
        'Error on sending Saving Chili Creation postMessage',
        error
      );
    }
  }

  /**
   * Check if Typologie finished
   */
  checkTypologie() {
    let idProduit = this._GlobalDataService.Campagne?.IdProduit;
    if (!idProduit || idProduit === '0') {
      console.log('Check Typologie : pas de produit sélectionné.');
      return false;
    }
    return true;
  }

  /**
   * Call API check user Authentication
   */
  async checkAuthentication() {
    try {
      const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
      this.isAuthenticated = await this._paoService
        .getAuthentication(idCampagne)
        .toPromise();
      console.log('Result Authentication PAO: ', this.isAuthenticated);
    } catch (error) {
      this._GlobalDataService.alert(
        `Nous rencontrons actuellement un problème technique.<br />
        Veuillez réessayer ultérieurement. Merci de votre compréhension.`
      );
    }

    return this.isAuthenticated;
  }

  /**
   * Standalone Env
   * From 'Upload' to 'Chili'
   * Delete fichier de ciblage
   * Delete champs fusion: personnalisation and pave adresse
   */
  deleteStandaloneConfigurations() {
    console.log('Emptying fichier de ciblage and champs fusion');
    //this.isDeletingFichierCiblage = true;

    // Delete creations file
    this.deleteUploadFilesCall();

    // Call delete fichier de ciblage
    //this.deleteFichierCiblage();

    // Delete champs personnalisation in Front
    this._personalisationService.empty();

    // Delete champs pave adresse in Front
    this._adressesDestinatairesService.empty();

    // Delete champs fusion in DB
    //this.deleteChampsFusionDB();
  }

  /**
   * Salesforce Env
   * From 'Upload' to 'Chili'
   * Delete champs fusion: personnalisation and pave adresse
   */
  deleteSalesforceConfigurations() {
    console.log('Emptying champs fusion');

    // Delete ExtensionId in Campagne
    //this._GlobalDataService.Campagne.ExtensionId = "";
    //this.updateCampagne();

    // Delete creations files
    //this.deleteUploadFilesCall();

    // Empty champs personnalisation in Front
    this._PersonalisationSfService.selectedChampsFusion = [];
    this._PersonalisationSfService.filteredAvailableChampsFusion = [];
    this._PersonalisationSfService.availableChampsFusion = [];

    // Empty sharedListName in _PersonalisationSfService form
    this._PersonalisationSfService.form.get("sharedListName").patchValue('', { emitEvent: false });
    
    // Empty champs pave adresse in Front
    this._AdressesDestinatairesSfService.availableChampsFusion = [];
    this._AdressesDestinatairesSfService.filteredAvailableChampsFusion = [];
    this._AdressesDestinatairesSfService.selectedChampsFusion = [];

    // Empty sharedListName in _AdressesDestinatairesSfService form
    this._AdressesDestinatairesSfService.form.get("sharedListName").patchValue('', { emitEvent: false });

    // Empty champs fusion in CampagneExt
    this._GlobalDataService.CampagneExt.ChampsFusion = []
    console.log("Champs fusion: " + this._GlobalDataService.CampagneExt.ChampsFusion);

    // Delete champs fusion in DB
    //this.deleteChampsFusionDB();
  }

  /**
   * Eloqua Env
   * From 'Upload' to 'Chili'
   * Delete champs fusion: personnalisation and pave adresse
   */
  deleteEloquaConfigurations() {
    console.log('Emptying champs fusion');

    // Delete creation files
    //this.deleteUploadFilesCall();

    // Delete champs personnalisation in Front
    this._personalisationService.empty();

    // Delete champs pave adresse in Front
    this._adressesDestinatairesService.empty();

    // Delete champs fusion in DB
    //this.deleteChampsFusionDB();
  }
  

  /**
   * Delete champs fusion in DB
   */
  deleteChampsFusionDB() {
    this._ChampsFusionService.upsert({
      IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
      ChampsFusion: []
    }).subscribe({
      next: (_) => {
        console.log("Champs fusion have been deleted.");
      },
      error: (_) => {
        console.log("Failed to delete Champs fusion.");
      }
    });
  }

  showFichierCiblage() {
    this.isCiblageLoaded = true;
    console.log('Show Fichier de ciblage.');
  }

  /**
   * Delete fichier de ciblage and update campagne
   */
  deleteFichierCiblage() {
    console.log('Delete fichier de ciblage and update campagne');

    // Update Campagne
    const resetCampagne = () => {
      this._GlobalDataService.Campagne.importFile = '';
      this._GlobalDataService.Campagne.importOption = undefined;
      this.updateCampagne();
      this.isDeletingFichierCiblage = false;
    }

    // No fichier de ciblage uploaded
    if (
      !this._GlobalDataService.Campagne.importFile ||
      this._GlobalDataService.Campagne.importFile.length <= 4
    ) {
      console.log('No valid fichier de ciblage to be deleted ');
      resetCampagne()
      return;
    }

    // Delete fichier de ciblage
    this._fichierPersoStandaloneService
      .delete(this._GlobalDataService.Campagne.IdCampagne)
      .subscribe({
        next: (_) => {
          console.log('Successfully deleted fichier de ciblage.');
          resetCampagne();
        },
        error: (_) => {
          console.log(
            'Erreur technique pendant la suppression du fichier de ciblage.'
          );
          this._GlobalDataService.alert(
            'Erreur technique pendant la suppression du fichier de ciblage.'
          );
          this.isDeletingFichierCiblage = false;
        },
      });
  }

  private updateCampagne() {
    this._CampagneService.put(this._GlobalDataService.Campagne).subscribe({
      next: () => {
        console.log(
          'Campange has been updated: ',
          this._GlobalDataService.Campagne
        );
      },
      error: (err) => {
        console.log(err);
        this._GlobalDataService.alert(
          "Une erreur s'est produite pendant la mise à jour de la campagne."
        );
      },
    });
  }

  // Go to next step or previous step
  // Check saving Chili Creation if iframe is opened
  public goTo(step: 'next' | 'previous') {
    if(this.isSaveSendToChili) {
      return;
    }
    console.log(this._GlobalDataService.isIframeChiliLoaded);

    // Classic mode
    if (
      this._GlobalDataService.Campagne.creationType !== 'chili' ||
      !this._GlobalDataService.isIframeChiliLoaded
    ) {
      if (step === 'next') {
        console.log('Go to Personnalisation.');
        this._stepper.next();
      } else {
        console.log('Go to Typologie.');
        this.stepper.previous();
      }
      return;
    }

    // Creation mode
    const onSaveSuccess = () => {
      // Keep state and step for postMessage saved receive from chili
      this.isSaveSendToChili = true;
      this.stepChoose = step;
      this.sendSavingPostMessage();
    };

    const onSaveCancel = () => {
      console.log('Status of Chili iframe : Loaded');
      return;
    };

    this._GlobalDataService.confirm({
      // titre: 'Attention !',
      message:
        'Attention vous quittez le Studio de création, sauvegarder votre progression.',
      BouttonConfirmation: true,
      confirmMsg: 'Sauvegarder et quitter le studio',
      onConfirm: onSaveSuccess.bind(this),
      onCancel: onSaveCancel.bind(this),
    });
  }

  // Go to step 5 Planification or step 4 Personnalisation
  goToShortCut() {
    const onCancel = () => {
      this._stepper.selectedIndex = 4;
    };
    const onSuccess = () => {
      this._stepper.next();
    };

    this._GlobalDataService.confirmShortCutOption({
      message:
        'Voulez-vous visualiser vos données de personnalisation ou passer directement à l’étape de planification ?',
      cancelMsg: 'Passer à la planification',
      onCancel: onCancel.bind(this),
      onConfirm: onSuccess.bind(this),
    });
  }

  public openHelp(signet: string) {
    this._GlobalDataService.referenceDocHelp = '#' + signet;
    this._HelpComponent.OpenHelpFile();
  }

  /**
   * Handle Chili Iframe postMessages
   * @param event a MessageEvent
   */
  private handleChiliPostMessage(event: MessageEvent): void {
    // Loading postMessages
    if ('iframe_symfony' in event.data) {
      const data = event.data as {
        iframe_symfony: {
          hasError: boolean;
          message: string;
          chili: 'fullyRendered' | 'loading';
          templatesType?: 'GENERIC' | 'CUSTOM' | null;
        };
      };

      // Template types
      if (!this.templatesType) {
        this.templatesType = data.iframe_symfony.templatesType;
        if (
          this.templatesType === 'CUSTOM' ||
          this._GlobalDataService.CampagneExt.chiliCreationStatus
        ) {
          this._GlobalDataService.isIframeChiliLoaded = true;
        } else if (this.templatesType == 'GENERIC') {
          this._GlobalDataService.confirm({
            message: `
              Vous n’avez pas de template personnalisé défini pour le produit sélectionné, veuillez-vous rapprocher de votre contact au sein de La Poste pour créer un template personnalisé.
              <br /><br />
              Souhaitez-vous utiliser le template par défaut ?`,
            BouttonConfirmation: true,
            confirmMsg: 'Oui',
            onConfirm: () => {
              this._GlobalDataService.isIframeChiliLoaded = true;
            },
            cancelMsg: 'Non',
            onCancel: () => {
              this.iframe = undefined;
              this._GlobalDataService.isIframeChiliLoaded = false;
            },
          });
        }
      }

      // Loading state
      if (data.iframe_symfony.hasError === true) {
        console.log('Error on loading Chili: ', data.iframe_symfony.message);
        this.iframeHasError = true;
        this.isLoadingChiliIframe = false;
        this._GlobalDataService.isIframeChiliLoaded = true;
      }
      if (data.iframe_symfony.chili === 'fullyRendered') {
        this.isLoadingChiliIframe = false;
      }
    }
    
    // Validate templates
    if ('close_pao' in event.data) {
      const data = event.data as { close_pao: boolean };
      if (data.close_pao) {
        this.exitChili();
        return;
      }
    }

    // Saving postMessages
    if ('saved' in event.data) {
      const data = event.data as { saved: boolean };
      if (this.isSaveSendToChili) {
        if (data.saved === false) {
          // Todo: Handle error
        }

        // Reset vars and move to selected step
        this.isSaveSendToChili = false;
        setTimeout(() => {
          this.iframe = undefined;
          this._GlobalDataService.isIframeChiliLoaded = false;
          this.templatesType = undefined;
          console.log('Status of Chili iframe : closed');
          if (this.stepChoose === 'next') {
            console.log('Go to ShortCut check.');
            this.goToShortCut();
          } 
        }, 100);
      
      }
    }

    // Check template
    if ('templatesValid' in event.data) {
      const data = event.data as { templatesValid: boolean };
      // Update stepper completion
      if(data.templatesValid) { 
        this._GlobalDataService.CampagneExt.chiliCreationStatus = "VALIDEE";
      } else {
        this._GlobalDataService.CampagneExt.chiliCreationStatus = "BROUILLON";
      }
      this._GlobalDataService.checkStepCompletion();
    }

    // Fullpage mode
    if ('full_page' in event.data) {
      this._GlobalDataService.isChiliFullpageMode = event.data['full_page'];
    }
  }

  public displaySpinnerOverlay() {
    if (this.isLoadingChiliUrl) {
      return true;
    }

    if (
      this.iframe &&
      !this._GlobalDataService.isIframeChiliLoaded &&
      !this.templatesType
    ) {
      return true;
    }

    if (this.isDeletingFichierCiblage) {
      return true;
    }

    return false;
  }
}
